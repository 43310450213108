import { salesData } from "../../data/homePageData";
import profileImg from "../../assets/profileimg.svg";
import AboutCeoSection from "../Common/AboutCeoSection";
import "./home.css";
import FadeIn from "react-fade-in";

function SalesTeamSection({ showAboutCeo = false, para, fontFamily, styling }) {
    return (
        <div className="bg-[#0E1725] salesTeamSection_wrapper w-full flex flex-col items-center gap-[60px]  pt-[120px]">
            <section className="flex flex-col md:w-[90%] w-[100%] mx-auto gap-[30px] items-center">
                <p
                    style={{
                        fontFamily: "Avram-bold",
                    }}
                    className="font-[600] text-[40px] tracking-[1px] text-white salesTeam_sec1_para "
                >
                    A done-for-you sales team
                </p>
                <p
                    style={{
                        fontFamily: `${fontFamily}`,
                    }}
                    className={` ${styling} font-[200] text-[23px] text-center  w-[90%] md:w-[85%] lg:w-[80%] salesTeam_sec1_subPara`}
                >
                    {para}
                </p>

                <div className=" sale_data_wrapper sales_data_wrapper grid grid-cols-2 gap-[80px] pl-[50px] md:gap-[100px]   ">
                    {salesData.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-row gap-[4px]  single_sales_data w-[500px] items-center mx-auto sec1_num_title_container  lg:mr-[130px] "
                        >
                            <p
                                style={{
                                    fontFamily: "Roboto-regular",
                                }}
                                className={`text-[#42BDAC] ${
                                    item.num === "88%" ? "text-right pr-5" : ""
                                } sales_sec1_num  text-[64px] font-[400] w-[45%]  `}
                            >
                                {item.num}
                            </p>
                            <p
                                style={{
                                    fontFamily: "Poppins-bold",
                                }}
                                className="font-[700] w-[65%] sale_sec1_title text-[24px] tracking-[2px] text-white uppercase "
                            >
                                {item.title}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* another section */}
            {showAboutCeo && (
                <AboutCeoSection
                    aboutPara={
                        '"We saturated the Australian market and needed to scale the US. We engaged Grow10X to not only book meetings but GTM strategy. Our first meeting was booked on the second day. I strongly recommend Grow10X if you are looking to test GTM."'
                    }
                    subPara={"CEO, Advvy"}
                    name={"Tristan Ozinga"}
                    img={profileImg}
                />
            )}
        </div>
    );
}

export default SalesTeamSection;
