import { useState, useEffect ,useRef} from "react";
import CompanyDriven from "../Common/CompanyDriven";
import FooterSection from "../Common/FooterSection";
import Navbar from "../Common/Navbar";
import { setShowSMenu } from "../../reducer/slices/showMenuSlice";
import { booknowData, getStartData } from "../../data/registerData";
import { useDispatch } from "react-redux";
import "./register.css";
import { makeUnauthenticatedPOSTRequest } from "../../services";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {ImCross} from "react-icons/im"
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";


function Register() {

  const dispatch = useDispatch();
  const [showBookNow, setShowBookNow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setShowSMenu(false));
  }, []);

  const [formData, setFormData] = useState({
    budget: "",
    interestedSerivices: "",
    timeline: "",
  });


  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [bookNowForm , setBookNowForm] = useState({
    Name:"",
    Email:"",
    PhoneNumber:"",
    Company:""
  })

  const bookNowChange = (event)=>{
    const {name , value} = event.target;
    setBookNowForm((prev)=>(
      {
        ...prev , 
        [name]:value
      }
    ))
  }


const submitHandler = async(event)=>{
  try{
    
    event.preventDefault();

    const newFormData={
      ...bookNowForm , ...formData
    }

    const response = await makeUnauthenticatedPOSTRequest(newFormData);
    
  if(response.success === true){
    setShowPopup(true)

  }
   
        
  } catch(error){
    console.log(error);
  }
}


  return (
    <div className="bg-[#0E1725] h-auto  overflow-x-hidden flex flex-col gap-[100px]">
      <Navbar />

<FadeIn>
        <main className="w-full pt-[197px] pitchMe_wrapper pl-[100px] pr-[100px] flex flex-row gap-[35px]  ">
        {/* left part  */}
        <div className="w-[45%] flex mt-10 flex-col gap-[50px] pitchMe_left_container ">
          <p
            style={{
              fontFamily: "Poppins-bold",
            }}
            className="text-white font-[700] text-[60px] pitchMe_left_heading "
          >
            There's a better way to prospect
          </p>
          <p
            style={{ fontFamily: "Roboto-regular" }}
            className="text-[23px] pitchMe_para tracking-[0.5px] text-white font-[500] "
          >
            With better data and outbound expertise, we source, engage, and
            schedule qualified meetings with your ideal customers most likely to
            convert into revenue.
          </p>
        </div>

        {/* right */}
        <div className="flex flex-row relative w-[650px] form_wrapper h-[750px] overflow-x-hidden ">
          <div
            className={` flex flex-row   overflow-hidden   border-2 h-[700px]  border-white p-[50px]  bg-[#42BDAC33] bg-opacity-20  rounded-lg   w-[650px]  pitchMe_right_container  `}
          >
            {/* this is for get start */}
            <form
              onSubmit={(e) => {
                console.log("for", formData);
                console.log("sumibte");
                e.preventDefault();
                setShowBookNow(true);
              }}
              className={` ${
                showBookNow ? "getStart" : ""
              } flex flex-col w-full  gap-10 `}
            >
              <p
                style={{
                  fontFamily: "Roboto-regular",
                }}
                className={`getStartHeading  text-white font-[400] text-[36px]  `}
              >
                Ready to accelerate your revenue pipeline{" "}
                <span className="text-[#42BDAC]"> ?</span>
              </p>
              <p
                style={{
                  fontFamily: "Roboto-regular",
                }}
                className={`getStartSubHeading text-white font-[300] text-[24px] w-full  text-left  `}
              >
                Answer the following questions to get your pitch:
              </p>

              <div className={`flex flex-col gap-[11px] w-[100%]  `}>
                {getStartData.map((data, index) => (
                  <div
                    key={index}
                    className={`flex flex-row gap-[16px] single_form_input items-center w-[100%]  h-[80px]  border border-white rounded-[8px] p-[25px] `}
                  >
                    <img src={data.img} alt="" className="max-w-[20px]  " />

                    <select
                      onChange={onChangeHandler}
                      required
                      name={data.name}
                      id=""
                      className="font-[700] text-[16px] bg-transparent tracking-[1px] text-left w-full outline-none border-none  text-white form_title"
                    >
                      <option
                        value=""
                        style={{
                          fontFamily: "Poppins-bold",
                        }}
                        className="font-[600] tex-left text-[16px] tracking-[1px] text-black"
                        selected
                        disabled
                      >
                        {data.defaultTitle}
                      </option>
                      {data.data.map((item, index) => (
                        <option
                          value={item}
                          style={{
                            fontFamily: "Poppins-bold",
                          }}
                          className="font-[600] tex-left text-[16px] tracking-[1px] text-black"
                          aria-required
                          key={index}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
              <div className={`w-full flex justify-end`}>
                <button
                  type="submit"
                  style={{
                    fontFamily: "Poppins-bold",
                  }}
                  className="w-[150px] h-[48px] bg-[#42BDAC] text-white font-[600] text-[16px] tracking-[1px] text-center rounded-[8px] "
                >
                  Get Started
                </button>
              </div>
            </form>
          </div>

          {/* this is for book now */}
          <form  onSubmit={submitHandler}
            className={`p-[50px] ${
              showBookNow ? "bookNow" : ""
            } flex w-[650px] absolute top-0 bottom-0 left-0 right-0 translate-x-[100vw]  flex-col h-[750px] pitchMe_right_container bookNow_wrapper gap-10`}
          >
            <p
              style={{
                fontFamily: "Roboto-regular",
              }}
              className={`getStartHeading text-white font-[400] text-[36px]  `}
            >
              Ready to accelerate your revenue pipeline{" "}
              <span className="text-[#42BDAC]"> ?</span>
            </p>
            <p
              style={{
                fontFamily: "Roboto-regular",
              }}
              className={`getStartSubHeading text-white font-[400] text-[21px] w-full  text-left  `}
            >
              Provide a few contact details so we know how to Pitch you.
            </p>

            <div className={`  flex flex-col gap-[11px] `}>
              {booknowData.map((data, index) => (
                <div
                  key={index}
                  className={`flex single_form_input flex-row gap-[16px] items-center w-[550px] h-[65px] border border-white rounded-[8px] p-[25px] `}
                >
                  <img src={data.img} alt="" className="max-w-[20px]  " />
                  <input required name={`${data.name}`} value={bookNowForm[data.name]} onChange={bookNowChange} placeholder={`${data.title}`}
                    style={{ fontFamily: "Poppins-bold" }}
                    className="font-[700]  text-[16px] tracking-[1px] pl-2 outline-none border-none text-white form_title bg-transparent w-[100%] text-left"
                  />
                    
                 
                </div>
              ))}
            </div>
            <div className={`w-full flex justify-end`}>
              <button
                style={{ fontFamily: "Poppins-bold" }}
                className="w-[150px] h-[48px] bg-[#42BDAC] text-white font-[600] text-[16px] tracking-[1px] text-center rounded-[8px] "
                onClick={onclick}
              >
                Book now
              </button>
            </div>
          </form>

          {/* Popup component */}
      {showPopup && (
        <Popup className="bg-red-300 w-[100%] h-[100%]  "
          open={showPopup}
          closeOnDocumentClick
          onClose={() => setShowPopup(false)}
        >
           {close => (
      <div className="bg-[#0E1725] p-4 rounded-lg">
        <div  className="flex w-full justify-end">

        <button style={{
          fontFamily:"sebino-regular"
        }} className="close w-[35px] h-[35px] text-xl rounded-[50%] bg-[#0E1725] text-white flex justify-center items-center " onClick={close}>
         <ImCross/>
        </button>
        </div>
        <div style={{
          fontFamily:"sebino-regular "
        }} className="text-[20px] font-[500] text-white "> Thank you for submitting your details. we'll get back to soon</div>
        
        
      </div>
    )}
        </Popup>
      )}
        </div>
      </main>
      </FadeIn>


      <CompanyDriven color="bg-[#0E1725]" />

      <FooterSection />
    </div>
  );
}

export default Register;


