import commaImg from "../../assets/commaImg.svg"
import "./common.css"
import FadeIn from 'react-fade-in';




function AboutCeoSection({aboutPara , subPara , img , name , background = 'bg-[#0E1725]'}){
    return (
        <section className={ `py-12 ${background} aboutCeo_wrapper mt-[150px] pb-20 flex flex-row px-[100px]  gap-[38px]  items-center `}>
        {/* left */}
        
        <img src={img} className="max-w-[200px] translate-y-[-30px] "  alt="" />
        {/* right */}
              <div className="flex flex-col gap-[30px] items-center">
                <img src={commaImg} alt="" className="w-[85px]" />
                <p style={{
                    fontFamily:"Avram-bold"
                }} className="aboutCeo_para font-[600] text-[25px] text-center text-white ">{aboutPara}</p>
                <p style={{
                    fontFamily:"Poppins-bold"
                }} className="aboutCeo_subpara font-[700] mt-[-10px] text-[24px] text-center text-white ">{name}
<p style={{
    fontFamily:"sebino-regular"
}} className="aboutCeo_name font-[500] text-[20px] text-center text-opacity-80 text-[#D2D2D2] pb-20">{subPara} </p></p>
              </div>

    </section>
    )
}
export default AboutCeoSection;