import Navbar from "../Common/Navbar";
import growthLogo from "../../assets/growthLogo.svg"
import { useState } from "react";
import FooterSection from "../Common/FooterSection";
import { setShowSMenu } from "../../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "./aboutGrowth.css"
import FadeIn from "react-fade-in/lib/FadeIn";

function AboutGrowth(){

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setShowSMenu(false));
    },[])

    const [differenceData , setDifferenceData] = useState([
        {
            title1: " Experienced Team. ",
            title3:"Measurable Performance.",
            title2:"High Churn Rate.",
            title4:" Unpredictable Results." ,
            isShow:false ,
        },
        {
            title1: "Plug and play. ",
            title3:"Fast onboarding.",
            title2:"High Setup Fees. ",
            title4:"Ongoing Contracts." , 
            isShow:false ,
        },
        {
            title1: " Ongoing Training and Coaching.",
            title3:"Improved Outcomes.",
            title2:"Focused on Hitting Numbers.",
            title4:"Low Conversion Rate." , 
            isShow:false ,
        },
        {
            title1: "Professional Team.",
            title3:"Enhance Your Brand. ",
            title2:"Low Quality Leads. ",
            title4:"Frustration For Sales Team.",
            isShow:false ,
        },
        {
            title1: " Work With You As A Team.",
            title3:"Sharing Best Practices.",
            title2:"Function As External Team.",
            title4: "Low Visibility Into Process." , 
            isShow:false ,
        },
         {
    
         }
    
    ])


    const showDifference = (index)=>{
        setDifferenceData((prevData) => {
            const newData = [...prevData];
            newData[index].isShow = true;
            return newData;
        });
    }

    return (
        <div className="flex flex-col about_growth_wrapper gap-[150px] bg-[#0E1725] ">
  <Navbar/>

<FadeIn >


<section className="flex flex-col pt-[157px] w-full ">
         {/* top section  */}
         <div className="flex flex-row items-center diffence_logo_container justify-evenly  ">
            <img src={growthLogo} alt="" className="growthLogo_img" />
            <p style={{fontFamily:"Poppins-bold"}} className="font-[700] text-[40px] text-white other_agencies_text">Other Agencies</p>
         </div>

         {/* difference part  */}
         <div className="flex flex-col aboutGrowth_diff_container w-[1000px] = mx-auto mt-20 ">
          {
            differenceData.map((data , index)=>(
                <div style={{fontFamily:"Heebo"}} key={index} className="flex flex-row gap-[100px] single_diff_container " >
                    {/* left data  */}

                    <p style={{fontFamily:"Poppins-bold"}} className={`   w-[585px] translate-y-[210px] text-white left_side_text font-[500] text-center text-[24px] `}>{data.title1} 
                    <br /> {data.title3}</p>


                    {/* line wrapper */}
                    <div className="flex flex-col items-center ">
                     
                     {/* line */}
                     <div className="bg-[#B3B3B3] w-[4px] aboutgrowth_line h-[250px] "></div>
                     <div onClick={()=>showDifference(index)} className={`bg-[#42BDAC] w-[16px] h-[12px] cursor-pointer ${differenceData.length === index+1?('hidden'):('')} `}></div>

                    </div>

                    {/* right data */}
                    <p style={{fontFamily:"Poppins-bold"}} className={` w-[540px] translate-y-[210px] text-white right_side_text font-[500] text-center text-[24px] `}>{data.title2}
                    <p>{data.title4}</p></p>
                </div>
            ))
          }

         </div>


</section>
</FadeIn>
<FooterSection />

        </div>
    )
}

export default AboutGrowth;