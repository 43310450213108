import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Register from "./Pages/Register/Register";
import AboutUs from "./Pages/AboutUs/AboutUs";
import AboutGrowth from "./Pages/AboutGrowthPage/AboutGrowth";
import Method from "./Pages/Method";
import UniqueSystemPage from "./Pages/UniqueSystemPage";
import SecretPage from "./Pages/SecretPage";
import WhatToDo from "./Pages/WhatToDo";


function App() {
  

  return (
    // this is wrapper
    <div className={` w-full h-full overflow-x-hidden relative`}>
      <Routes>
        <Route path="/" element={<Home/>}  />
        <Route path="/YesPitchMe" element={<Register/>}  />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/VsOtherAlternatives" element={<AboutGrowth />}  />
        <Route path="/method" element={<Method />}  />
        <Route path="/listenToCalls" element={<UniqueSystemPage />} />
        <Route path="/secretSauce" element={<SecretPage />} />
        <Route path="/services" element={<WhatToDo />} />
      </Routes>
    </div>
  );
}

export default App;
