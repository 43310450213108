export const WhatToDodata = [
    {
        title: "Outsource SDR",
        para: "Our premium and most successful offering. Here you get to experience end to end lead generation system from identifying prospects to booking demos or appointments. ",
    },
    {
        title: "Build OutBound System",
        para: "We will build from scratch the exact system That has made our outbound program so effective. We will start with how to identify the right personas, cold calling script, email templates and cadences. ",
    },
    {
        title: "Training and Coaching",
        para: "Your team needs additional cold call training or coaching? We can help with your 8+ years experience in cold calling In additional we can look at your email and identify where you can improve in the messaging. ",
    },
    {
        title: "SDR Recruitment",
        para: "Looking for a SDR? We can help by identifying low cost yet proven offshore SDR. We can also help them with training and coaching ",
    },
    {
        title: "Webinar Event",
        para: "Are you looking to generate leads from webinar events? We have proven record of running successful webinar campaigns. We will build webinar event and drive attendees to your event. To generate appointments, we will do follow up calls to attendees.",
    },
    {
        title: "Listen to calls",
        para: "Our Unique System is the Secret Sauce to your Growth.",
    },
];
