export const navItems = [
  {
    title: "Services",
    move: "/services",
  },

  {
    title: "Method",
    move: "/method",
  },
  {
    title: "Secret Sauce ",
    move: "/secretSauce",
  },

  {
    title: "Vs Other Agencies",
    move: "/VsOtherAlternatives",
  },
  {
    title: "About us",
    move: "/aboutUs",
  },
];

export const footerItem = [

  {
    title: "Services",
   move:"/services"
  },
  {
    title: "Method",
    move: "/method",
  },
  {
    title: "Secret Sauce",
    move: "/secretSauce",
  },

  {
    title: "About Us",
    move: "/aboutUs",
  },
];
