import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 showMenu:false ,
};

const authSlice = createSlice({
  name: "showMenu",
  initialState: initialState,
  reducers: {
    setShowSMenu(state , value){
        state.showMenu = value.payload
    }
  },
});

export const { setShowSMenu } = authSlice.actions;

export default authSlice.reducer;