import us1 from "./assets/us1.svg"
import us2 from "./assets/us2.svg"
import us3 from "./assets/us3.svg"
import us4 from "./assets/us4.svg"
import us5 from "./assets/us5.svg"

export const  uniqueSystem = [
    {
        num:"$1.3M",
        title:"New sales revenue",
     img:us1 
    },
    {
        num:"3.7X",
        title:"More sales pipelines built",
     img:us2
    },
    {
        num:"189%",
        title:"Increase in SQL",
     img:us3
    },
    {
        num:"5X",
        title:"ROI vs Internal team",
     img:us4
    },
    {
        num:"$489K",
        title:"Revenue in 6 months",
     img:us5
    },
]