import location from "../assets/location.svg";
import email from "./assets/email.svg";

export const IndustryProperties1 = [
    "SaaS",
    "Emerging Technology",
    "IoT Hardware",
    "Manufacturing",
    "Logistic",
];

export const IndustryProperties2 = [
    "Construction",
    "Property Development",
    "Mining",
    "Legal",
];

export const salesData = [
    {
        num: "6.7K",
        title: "Demos Booked",
    },

    {
        num: "186K+ ",
        title: "Calls made",
    },

    {
        num: "60+",
        title: "Client partnered",
    },

    {
        num: "88%",
        title: "PROGRAMS MET KPI’s",
    },
];

export const footerData = [
    {
        image: location,
        title: "200 Adelaide St, Brisbane City QLD 4000, Australia",
    },
];

export const data = [
    {
        title: "Appointment Setting",
        para: "We will use our proven method in cold calling, email and LinkedIn to build sales pipelines for you.  We will never spam your prospects with massive bulk email. All of our calls and emails are researched and personalised. This to ensure its effectiveness and your brand and reputation remain intact.",
    },
    {
        title: "Webinar Events",
        para: "We will create a webinar event and drive more attendees through cold calling and 1-1 email marketing. We will execute follow up calls post webinar to book demos. Anyone who has not attended we will reach out to share the recorded webinar and follow up to book demos. ",
    },
    {
        title: "Top-of-Funnel Training",
        para: "Your internal SDR or sales team not building enough appointments? We will share our best practices including cold call training and unique ways to get 27.3% response rate from your outbound prospecting so your team can book 12-17 appointments per month.",
    },
];

export const navItems = [
    "Menu",
    "Home",
    "About us",
    "Services",
    "Hiring SDR’s vs Us",
];
