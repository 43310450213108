import "./experienceTeam.css"
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide  } from 'swiper/react';
import {Navigation,Pagination,A11y  } from 'swiper/modules';
import { SwiperNavButtons } from "../../Components/SwiperNavButton";
import { useEffect , useState } from "react";


function ExperienceTeam(){

  const [slidesPerView, setSlidesPerView] = useState(6); 
  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      
      if (window.innerWidth < 560) {
        setSlidesPerView(5.7); // Change slidesPerView for screens < 500px
      }
      else if(window.innerWidth <990){
        setSlidesPerView(5.8)
      }
      else if(window.innerWidth < 1370){
        setSlidesPerView(5.7)
      }
       else {
        setSlidesPerView(6.1); // Revert to the default value for screens >= 500px
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial setup based on current window size
    handleResize();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log('slideb' , slidesPerView);

    return (
        <section className="mt-[170px] ml-[20px] about_sec4 flex flex-col items-center gap-10   ">
          
            <p className="text-[#42BDAC]  font-[700] about_sec4_heading text-[30px] tracking-[6px]  ">AN EXPERIENCED TEAM</p>
            <Swiper
            className=" "
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={60}
          slidesPerView={slidesPerView}
          speed={3000}
         effect="slide"          
        >
          <SwiperSlide className="res-slide first_img ml-[100px] " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          <SwiperSlide className="res-slide " />
          
            <SwiperNavButtons    />
        </Swiper>

         
          </section>
    )
}

export default ExperienceTeam;
