import g1 from "../assets/g1.svg";
import g2 from "../assets/g2.svg";
import g3 from "../assets/g3.svg";
import g4 from "../assets/g4.svg";
import g5 from "../assets/g5.svg";
import g6 from "../assets/g6.svg";
import g7 from "../assets/g7.svg";
import g8 from "../assets/g8.svg";
import g9 from "../assets/g9.svg";
import g10 from "../assets/g10.svg";
import g11 from "../assets/g11.svg";
import g12 from "../assets/g12.svg";
import g13 from "../assets/g13.svg";
import g14 from "../assets/g14.svg";
import g15 from "../assets/g15.svg";
import g16 from "../assets/main-logo 1.svg";
import g17 from "../assets/g17.svg";
import g18 from "../assets/g18.svg";
import g19 from "../assets/g19.svg";
import g20 from "../assets/g20.svg";
export const imageData = [
    g1,
    g2,
    g3,
    g4,
    g5,
    g6,
    g7,
    g8,
    g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
    g16,
    g17,
    g18,
    g19,
    g20,
];
