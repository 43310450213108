import person from "./assets/person.svg";
import letter from "./assets/letter.svg";
import letter2 from "./assets/letter2.png";
import hand from "./assets/hand.svg";
import call from "./assets/call.svg";

export const secretData = [
    {
        title: "Best Outbound Email. ",
        para: "B2B buying process is a collaborative decision making process. That is why we include 3-5 people in one email to initiate the best response. We include screenshot in our email to ensure prospects can visualise your solution.",
        num: "01",
        img: letter,
    },
    {
        title: "Personas Based Cadence ",
        para: "Targeting 20-50 employees of a legal firm is not the same as 250-500+ employees of an insurance firm. We tailor our cadence or sequence based on the number of people involved in the decision making process, how they buy and buying cycle.",
        num: "02",
        img: person,
    },
    {
        title: "Leverage Hierarchy",
        para: "We have made more than 20k+ plus calls over the last 8 years. The best way to get a response from C-Level is not to call directly. We cold call someone they trust such as a colleague to get an introduction.",
        num: "03",
        img: call,
    },
    {
        title: "Engage Multiple People To Penetrate Large Accounts",
        para: "Traditional prospecting involves one SDR or sales person sending emails or calling to a large company. This has proven to be ineffective. We have flipped the script by involving 3 people to penetrate the account including calling, emailing and LinkedIn outreach.",
        num: "04",
        img: hand,
    },
    {
        title: " Best Email Combined With Most Effective Cold Calling Technique ",
        para: "We have sent 37K plus emails and understand what templates works. Our cold calling team is given ongoing training and coaching to ensure they book appointments but also to enhance your brand and reputation.",
        num: "05",
        img: letter2,
    },
];
