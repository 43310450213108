import React from 'react';
import { useSwiper } from 'swiper/react';
import {BsArrowLeft} from "react-icons/bs"
import {BsArrowRight} from "react-icons/bs"


export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className=' h-[200px] w-[90vw] flex translate-y-[80px] translate-x-[-10px] justify-end'>

    <div className=" w-[150px] h-[70px] rounded-[50px] justify-evenly flex flex-row border border-[#42BDAC] translate-y-[40px] translate-x-[20px] z-10  "  >
      <button className='flex items-center justify-center cursor-pointer text-white text-[30px] w-[50%] hover:bg-[#42BDAC] rounded-l-[50px]  ' onClick={() => swiper.slidePrev()}><BsArrowLeft /></button>
      <button className='flex items-center justify-center text-white text-[30px] z-20  cursor-pointer hover:bg-[#42BDAC] rounded-r-[50px] w-[50%]   '  onClick={() => swiper.slideNext()}><BsArrowRight /></button>
    </div>
    </div>
  );
};