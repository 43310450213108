import {imageData} from "../../data/homeImageData";




function CompanyDriven({color = 'bg-[#1C2635]'}){
    return (
        <section className={`${color} companyDrivenWrapper  w-full h-full mt-[-10px] p-[65px]  flex flex-col items-center gap-[100px] overflow-x-hidden`}>
        
  
        {/* heading  */}
        <p style={{fontFamily:"Poppins-bold"}} className="font-[600] text-white text-[40px] tracking-[1.5%] text-center company_driven_heading  overflow-x-hidden">Companies driving revenue with Grow10X<span className="text-[#42BDAC] ">.</span></p>
        
        {/* logo items  */}
        <div className="flex flex-row flex-wrap gap-[100px] space-y-1 justify-center  overflow-x-hidden company_logo_container max-w-[1570px] ">
         {
          imageData.map((data ,index)=>(
            <img src={data} key={index} alt="" className="single_company_img w-[185px]  h-[100px]  "  />
          ))
         }
        </div>
        
       
              </section>
    )
}

export default CompanyDriven;