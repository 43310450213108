import getStart from "../assets/getStart.svg"
import bookNow from "../assets/bookNow.svg"

export const getStartData = [
{
    img:getStart ,
    name:"interestedSerivices", 
    defaultTitle:"What services are you interested?",
    data:[
        "Sales appointments",
        "Cold call training",
        "Build outbound system"
    ]
},
{
    img:getStart ,
name:"budget",
    defaultTitle: "What's your budget ?",
   data:[
   
    "$1,500 to $2,500",
    "$3,500 to $5,000",
    "$5,000 to $7,000",
    "$7,000 to $10,000",
    "Above $10,000",
   ]
},
{
    img:getStart , 
    name:"timeline",
    defaultTitle: "What is your Timeline",
    data:[
       
        "Immediate need",
        "Within 30 Days",
        "30 to 60 Days",
        "60 to 90 Days",
        "Just researching options",
        "Evaluating to another agency"
    ]
},
]

export const booknowData = [
    {
        img : bookNow , 
        title:"Email",
        name:"Email"
    },
    {
        img : bookNow , 
        title:"Name" , 
        name:"Name"
    },
    {
        img : bookNow , 
        title:"Company" , 
        name:"Company"
    },
    {
        img : bookNow , 
        title:"Phone Number" , 
        name:"PhoneNumber"
    },
]