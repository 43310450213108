import "./aboutUs.css"
import Navbar from "../Common/Navbar";
import aboutUsImg from "../../assets/aboutUsImg.svg"
import dotImg from "../../assets/dotImg.svg"
import SalesTeamSection from "../Home/SalesTeamSection";
import img1 from "../../assets/img1.svg"
import AboutCeoSection from "../Common/AboutCeoSection";
import ceoProfile from  "../../assets/ceoProfile.svg"
import FooterSection from "../Common/FooterSection";
import { setShowSMenu } from "../../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ExperienceTeam from "./ExperienceTeam";
import FadeIn from 'react-fade-in';


function AboutUs(){
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setShowSMenu(false));
    },[])
    return (
        <div className="w-full bg-[#0E1824] z-10  ">
          <Navbar />

          {/* section 1  */}
          <FadeIn >
          <section className="flex  aboutUs_section1 flex-row md:gap-[85px] pl-[20px] pr-[20px] lg:pl-[8px] lg:pr-[100px] pt-[240px] justify-evenly">
           {/* left part  */}
           <div className="w-[55%] flex flex-col gap-[30px] lg:gap-[40px] lg:mt-10 mt-20 aboutUs_section1_left ">

               <p style={{fontFamily:"Poppins-bold"}} className="text-white aboutUs_text lg:text-[78px] lg:text-left text-center font-[600] text-[55px] ">About Us </p>

               <p style={{
                fontFamily:"Poppins-bold"
               }} className="lg:text-[40px] aboutUs_para text-[24px] font-[500] tracking-[3%] lg:text-left text-center text-white ">B2B outbound prospecting is changing. We have built a system that is ahead of the curve.</p>
           </div>

{/* right part  */}
<div>
    <img src={aboutUsImg} alt="" className="w-[379px] aboutUs_section1_right" />
</div>

          </section>
          </FadeIn>

          {/* another section  */}
          <section className="flex flex-col items-center w-full mt-[150px] ">
            <div className="max-w-[870px] about_section2_wrapper ">
        <p style={{
            fontFamily:"Poppins-bold"
        }} className="font-[700] text-[30px] pl-10 pr-10 tracking-[2%] about_section2_para text-white text-center ">


        Grow10X is your B2B outbound lead generation partner. We will get you in front of prospects faster than any other agency. Our unique method is proven to be effective in B2B sectors.</p>
            </div>

            <div className="w-[80%] flex justify-end">
           <img src={dotImg} alt="" className="about_dotImg  mt-[55px] mb-[35px] " />
            </div>
          </section>

          <SalesTeamSection para={'Hiring an internal sales development team is expensive and risky. We minimize your risk and maximize your revenue by providing an expert founder-led sales team focused on outbound strategy, data, content, technology, and prospecting.'} fontFamily={'Poppins-medium'} styling={'text-[#D2D2D2] '} />


          <section className="lg:ml-[88px] about_sec3 lg:mr-[88px] md:ml-[30px] md:mr-[30px] ml-[10px] mr-[10px] sm:ml-[20px] sm:mr-[20px] flex flex-row gap-[35px] justify-between mt-[230px] items-center">
          {/* left  */}
          <img src={img1} alt="" className="max-w-[366px] h-[370px] about_sec3_img " />
          {/* right  */}
          <p style={{
            fontFamily:"Poppins-bold"
          }} className="text-[33px] about_sec3_para font-[700] tracking-[1px] text-center text-white ">Our focus is to deliver personalised message so not only we get results but it will enhance your brand and reputation.</p>
          </section>

          {/* experience team section  */}

         <ExperienceTeam />

          
       
      <AboutCeoSection aboutPara={'"We had a great product. We’d just needed to get in front of right people. Grow10X method was perfect to our enterprise type sale. In 7 months our pipeline grew from 4 opportunities to 38. It was the best investment we’ve ever made."'} subPara={'Country manager, Jonas Premier'} name={'Michael Jantke'} img={ceoProfile} background="bg-[#1C2635]" />

      <FooterSection />
        </div>
    )
}

export default AboutUs;