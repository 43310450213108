import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import "./testimonial.css"

const Testimonial = () => {
    return (
        <>
            <Video
                width={100}
                autoPlay
                loop
                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                className="h-auto object-cover max-w-[550px] w-[40vw] video-component"
            >
                <source
                    width={100}
                    src={
                        "https://firebasestorage.googleapis.com/v0/b/grow10x-29819.appspot.com/o/01%20%20%20Grow10X%20Customer%20Testimonial%20%20%20Paul%20Tyrrell%20%20%20What%20is%20your%20name%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20_What%20is%20your%20-%20MERGE.mp4?alt=media&token=40dbc8ea-d198-4fc2-bf60-9ae9b5a03dfc"
                    }
                    type="video/mp4"
                />
            </Video>

            <p
                style={{
                    fontFamily: "Poppins-bold",
                }}
                className="text-white text-sm testimonial_text nowarp-text sm:text-lg md:text-xl text-center  font-bold"
            >
                ”The most effective lead generation <br />
                partner we have ever worked with” <br />
                Paul Tyrrell (FieldInsight Software)
            </p>
        </>
    );
};

export default Testimonial;
