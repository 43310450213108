export const methodData = [
    {
        day: "Day 1-5",
        title: "Onboarding ",
        desc: "Understand your target market, competition, value proposition and product demonstration. Setup CRM and Email",
    },
    {
        day: "Day 6-9",
        title: "Build Process ",
        desc: "Build cold call script. Craft personalised emails.",
    },
    {
        day: "Day 10-24",
        title: "Test System ",
        desc: "Test the message to 40 prospects per day.",
    },
    {
        day: "Day 25",
        title: "Analyse Metrics ",
        desc: "Evaluate the conversation rate of outreach to appointments Pivot if poor traction.",
    },
    {
        day: "Day 26",
        title: "Evaluate Progress ",
        desc: "Discuss traction including what's working",
    },
];
