import Navbar from "./Common/Navbar";
import { methodData } from "../data/method";
import s1 from "./assets/s1.svg";
import s2 from "./assets/s2.svg";
import s3 from "./assets/s3.svg";
import s4 from "./assets/s4.svg";
import s5 from "./assets/s5.svg";
import s6 from "./assets/s6.svg";
import AboutCeoSection from "./Common/AboutCeoSection";
import ceo from "./assets/methodCeo.svg";
import FooterSection from "./Common/FooterSection";
import { setShowSMenu } from "../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {AiOutlineMinus} from "react-icons/ai"
import "./page.css"
import FadeIn from 'react-fade-in';



const sourceData = [s1, s2, s3, s4, s5, s6];

function GrowthPage() {

  const [showDes , setShowDes] = useState([
   false ,
   false ,
   false ,
   false ,
   false ,
  ])

  const setDescValue = (index) => {
    if (index >= 0 && index < showDes.length) {
      const updatedShowDes = [...showDes]; 
      updatedShowDes[index] = !updatedShowDes[index];
      setShowDes(updatedShowDes);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowSMenu(false));
  }, []);


  return (
    <div className="bg-[#0E1725] w-full h-full flex flex-col gap-[150px] ">
      <Navbar />

      {/* method Section  */}
      <FadeIn>
      <section className="w-full pt-[280px] method_Sec1_wrapper  ">
        <p
          style={{
            fontFamily: "Poppins-bold",
          }}
          className="text-white pl-[80px] font-[600] text-[80px] method_sec1_heading mt-[-80px] mb-[100px]  "
        >
          Method
        </p>

        <div className="w-[980px] method_main_wrapper mx-auto flex flex-col  gap-[40px]  ">

          {methodData.map((data, index) => (

            <main key={index} className="flex flex-col gap-[10px]  ">
              {/* top */}
              <div className="flex flex-row method_main_top gap-[42px] w-[100%] ">
                {/* left  */}
                <div style={{
                  fontFamily:"Poppins-bold"
                }} className="w-[25%]  text-left   text-[#42BDAC] font-[600] method_main_day  text-[38px] ">
                  {data.day}
                </div>

                {/* right */}
                <div className=" flex flex-col gap-[13px] method_main_right w-[80%]  ">
                  <p className="text-[#42BDAC] font-[600] text-[38px] method_main_title flex flex-row items-center  ">
                    <p style={{
                      fontFamily:"Poppins-bold"
                    }} className="w-[50%]">{data.title}</p>
                    {
                      !showDes[index] ?  <AiOutlinePlus onClick={()=>setDescValue(index)} className="translate-x-[150px] text-white cursor-pointer method_plus " /> :<AiOutlineMinus onClick={()=>setDescValue(index)} className="translate-x-[150px] cursor-pointer method_plus text-white" />
                    }
                   
                  </p>
                  <p
                  style={{
                    fontFamily:"Avram-semibold"
                  }}
                    className={`w-[80%] font-[500] method_main_para text-[23px]  text-white pr-10 ${showDes[index] ?('animateMethodDesc'):('hidden h-0')}  `}
                  >
                    {data.desc}
                  </p>
                </div>
              </div>

              {/* bottom for line */}
              <div
                className={` ${
                  index !== methodData.length - 1
                    ? "w-full h-[2px] bg-white "
                    : ""
                }  `}
              ></div>
            </main>
          ))}
        </div>
      </section>
      </FadeIn>

{/* images sesction */}
      <section className="bg-[#1C2635] method_sec2 w-[100%] flex flex-col items-center  pt-[50px] pb-[50px] mx-auto overflow-x-hidden  ">
        <div className="w-[850px]  flex methodsec2_container flex-col gap-[60px] items-center ">
          <p
            style={{
              fontFamily: "Poppins-bold",
            }}
            className="text-white font-[600] text-center method_sec2_heading text-[40px] "
          >
            Source data from multiple sources
          </p>
          <div className="flex method_all_img_wrapper flex-row method_Sec2_img w-[700px]    text-center flex-wrap gap-[100px] mx-auto  ">
            {sourceData.map((data, index) => (
              <img
                key={index}
                className=" mx-auto w-[150px]  single_method_img"
                src={data}
                alt=""
              />
            ))}
          </div>
        </div>
      </section>

      {/* another section  */}
      <section className="mt-[-200px] mb-[-200px] ">
        <AboutCeoSection
          aboutPara={
            '  "Grow10X allowed our sales team to focus on selling not prospecting. We hated cold calling but knew this is the fastest way to build sales pipelines. The partnership  made our sales meetings buzzing with excitement - knowing  we now had consistent flow of leads."'
          }
          subPara={"Head of Sales, Console"}
          img={ceo}
          name={"Jonathan Stone"}
        />
      </section>

      <FooterSection />
    </div>
  );
}

export default GrowthPage;
