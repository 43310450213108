import dotimg from "../../assets/dotImg.svg";
import FadeIn from "react-fade-in";

function ProcessDrivenSection() {
    return (
        <section className="bg-[#0E1725] flex flex-col items-center gap-[30px] pt-[140px] pb-[100px] ">
            <div className="lg:w-[850px] px-5 mx-auto flex flex-col process_driven_sec1 gap-[30px] ">
                <p
                    style={{
                        fontFamily: "sebino-regular",
                    }}
                    className="font-[400] text-[18px] tracking-[6px] text-center text-[#42BDAC]  "
                >
                    PROCESS DRIVEN
                </p>
                <p
                    style={{
                        fontFamily: "Poppins-bold",
                    }}
                    className="text-[35px] processDeriven_heading text-white font-[700] text-center "
                >
                    We set the benchmark in B2B appointment settings and revenue
                    growth
                </p>
                <p
                    style={{
                        fontFamily: "sebino-regular",
                    }}
                    className="font-[400] text-[20px] processDerivenPara text-center text-white mt-10 "
                >
                    {" "}
                    By refusing to become another outsourced telemarketing
                    factory, our focus is to build the most effective outbound
                    system. This ensures you are able to scale without
                    increasing your client acquisition cost.
                </p>
            </div>

            <div className=" processDriven_img mt-20  flex justify-end w-[90%] ">
                {/* <img src={dotimg} alt="" className="text-right" /> */}
                <vouch-embed-player
                    style={{
                        display: "flex",
                        aspectRatio: "1.7777777777777777",
                        maxHeight: 300,
                    }}
                    preload="none"
                    apikey="lCKVpeaM55-ADAGvi1YltScnluVrDCwc8Z8FvbpfEhM82pqTcirmMyJBUy127"
                    vouchid="f0kPqUwspw"
                    templateid="e0ac8ef2-5db5-4d36-8484-476e1dd2bbbd"
                    aspectratio="1.7777777777777777"
                    language="en"
                    controls='["play-large","navigation","play","volume","current-time","duration","speed","captions","fullscreen","progress","preview"]'
                    questions="[]"
                />
            </div>

            <div className="w-full flex flex-col items-center gap-[11px] process_lead_wrapper mt-20 px-5 ">
                <p
                    style={{
                        fontFamily: "sebino-regular",
                    }}
                    className="font-[400] text-[18px] tracking-[6px] text-center text-[#42BDAC] uppercase "
                >
                    Lead GENERATION services
                </p>
                <p
                    style={{
                        fontFamily: "Avram-semibold",
                    }}
                    className="font-[600] text-[41px] tracking-[1px] text-center text-white lead_heading"
                >
                    How Grow10X Delivers Revenue
                </p>
                <p
                    style={{
                        fontFamily: "Avram-semibold",
                    }}
                    className="text-[26px] lead_para font-[500] text-center text-white  text-opacity-80 "
                >
                    {" "}
                    We are your competitive advantage by providing qualified
                    sales appointments at scale.
                </p>
            </div>
        </section>
    );
}

export default ProcessDrivenSection;
