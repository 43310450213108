const BASE_URL = process.env.REACT_APP_BASE_URL
console.log('base' , BASE_URL);

export const makeUnauthenticatedPOSTRequest = async ( body) => {

    try {
      const response = await fetch(BASE_URL +'/sendEmail', {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        // the body will send like this to backend
        body: JSON.stringify(body),
      });
  
      const formattedResponse = await response.json();
      return formattedResponse;
    } catch (error) {
      console.log(`error in fetch api `, error);
    }
  };