import Navbar from "./Common/Navbar";
import { WhatToDodata } from "../data/whatToDoData";
import FooterSection from "./Common/FooterSection";
import "./whatToDo.css";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";

function WhatToDo() {
    const navigate = useNavigate();

    return (
        <div className="bg-[#0E1725] w-full   ">
            <Navbar />

            <section className="flex flex-col gap-[100px]">
                <FadeIn>
                    <div className="pt-[177px] pl-[80px] w-[1000px] whatToDo_content_container flex flex-col gap-[30px] ">
                        <p
                            style={{
                                fontFamily: "Poppins-bold",
                            }}
                            className="text-white font-[600] text-[60px] whatToDo_heading"
                        >
                            What We Do
                        </p>
                        <p
                            style={{
                                fontFamily: "sebino-regular",
                            }}
                            className="text-[25px] text-white whatToDo_para "
                        >
                            Grow10X provides an experienced sales infrastructure
                            focused on sourcing, training, managing, and
                            retaining a talented workforce of Sales Development
                            Reps for growth focused companies.
                        </p>
                    </div>
                </FadeIn>

                <div className="flex box_wrapper flex-row ml-[90px] mr-[90px] gap-[100px] justify-center flex-wrap mb-[350px]">
                    {WhatToDodata?.map((data, index) => (
                        <div
                            className={`bg-white bg-opacity-[9%] w-[450px] transition-all whatToDoBox duration-75  group h-[362px] mt-[200px] flex flex-col  gap-[40px] p-[30px] rounded-[20px] single_box items-center justify-center `}
                            key={index}
                        >
                            <p
                                style={{
                                    fontFamily: "Poppins-bold",
                                }}
                                className="text-white text-[30px] whatToDo_title  group-hover:text-black  text-center"
                            >
                                {data.title}
                            </p>

                            <p
                                style={{
                                    fontFamily: "sebino-regular",
                                }}
                                className="text-white text-[20px] group-hover:text-black whatToDo_para hidden font-[500] group-hover:block"
                            >
                                {data.para}
                            </p>

                            {index + 1 === WhatToDodata.length && (
                                <button
                                    onClick={() => navigate("/listenToCalls")}
                                    style={{
                                        fontFamily: "Poppins-semibold",
                                    }}
                                    className="bg-[#42BDAC] w-[143px] px-[10px] py-[15px] border-white border-2 hover:border-0 hover:bg-[#79889e] hover:text-white rounded-[25px] "
                                >
                                    Learn More
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                <FooterSection />
            </section>
        </div>
    );
}

export default WhatToDo;
