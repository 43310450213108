import Navbar from "./Common/Navbar";
import { uniqueSystem } from "../data/systemData";
import FooterSection from "./Common/FooterSection";
import so1 from "./assets/so1.svg";
import AboutCeoSection from "./Common/AboutCeoSection";
import ceo3 from "./assets/ceo3.svg";
import { setShowSMenu } from "../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import MusicSection from "./Common/MusicSection";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";

function UniqueSystemPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setShowSMenu(false));
    }, []);
    return (
        <div className="w-full bg-[#0E1725] h-full   flex flex-col gap-[72px] ">
            <Navbar />

            <FadeIn>
                <section className="flex pt-[240px] flex-col items-center  gap-[50px] ">
                    <p
                        style={{
                            fontFamily: "Poppins-bold",
                        }}
                        className="w-[60%] unique_heading text-center text-white font-[600] text-[40px]  "
                    >
                        Our Unique System is the Secret Sauce to your Growth
                    </p>

                    <p
                        style={{ fontFamily: "sebino-regular" }}
                        className="text-[#42BDAC] font-[400] text-[19px] tracking-[6px] uppercase text-center "
                    >
                        Proven Track Record
                    </p>

                    <div className="flex w-full flex-row flex-wrap gap-[30px] text-center justify-center  ">
                        {uniqueSystem.map((data, index) => (
                            <div
                                key={index}
                                className="w-[220px] items-center pt-[20px] pl-[20px] pr-[20px] pb-[14px] h-[240px] rounded-[8px] flex flex-col gap-[15px] bg-[#42BDAC1A] border-2 border-white "
                            >
                                <p
                                    style={{ fontFamily: "Avram-semibold" }}
                                    className="text-[#42BDAC] font-[600]  text-[54px] h-[70px] "
                                >
                                    {data.num}
                                </p>
                                <p
                                    style={{
                                        fontFamily: "Avram-semibold",
                                    }}
                                    className="font-[500] text-[18px]  text-white h-[40%] "
                                >
                                    {data.title}
                                </p>
                                <img
                                    src={data.img}
                                    className="max-w-[150px] w-[90%]  text-center  h-[50px] "
                                    alt=""
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </FadeIn>

            {/* this is for music section  */}
            <MusicSection />

            {/* about CEO section */}
            <AboutCeoSection
                img={ceo3}
                background={"bg-[#1C2635] "}
                aboutPara={
                    '"PestSense is a new product targeting Councils. Our marketing efforts wasn’t giving great results so we needed a more direct approach. We also needed someone who can hit the ground running fast. The quality of opportunities created by Grow10X was exceptional. "'
                }
                subPara={"CEO, PestSense"}
                name={"Sam Donley"}
            />

            {/* this is card details  */}
            <section className="bg-[#0E1725] w-full pt-10 pb-10 ">
                <div className="bg-[#42BDAC] w-[1100px] unique_card_container h-[350px] rounded-[7px] mx-auto flex flex-col items-center gap-[25px] pt-[20px] pb-[20px] ">
                    <p
                        style={{
                            fontFamily: "Roboto-bold",
                        }}
                        className="font-[700] text-[23px] tracking-[6px] text-[#1C2635] text-center "
                    >
                        LIMITED CAPACITY
                    </p>
                    <p
                        style={{
                            fontFamily: "Poppins-bold",
                        }}
                        className="font-[700] text-[54px] text-center text-[#1C2635] w-[90%] unique_card_subHeading"
                    >
                        2 October Client Openings Remaining
                    </p>
                    <Link to={"/YesPitchMe"}>
                        {" "}
                        <button
                            style={{
                                fontFamily: "Poppins-bold",
                            }}
                            className="bg-[#1C2635] text-white w-[240px] h-[55px] rounded-[8px] text-[23px]   "
                        >
                            Request a Consult
                        </button>
                    </Link>
                </div>
            </section>

            <FooterSection />
        </div>
    );
}

export default UniqueSystemPage;
