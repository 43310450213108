import inImg from "../../assets/inImg.svg"
import arrow from "../../assets/arrow.svg"
import { footerData } from "../../data/homePageData";
import { footerItem} from "../../data/navItems";
import logo from "../../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import FadeIn from 'react-fade-in';




function FooterSection(){
    const navigate = useNavigate();
    const linkedInProfileUrl = 'https://www.linkedin.com/company/grow10x/?originalSubdomain=au'; // Your LinkedIn profile URL


    return (
        <div className="w-full bg-[#0A1423] px-[25px] py-[50px] flex flex-col mt-[-55px]  ">
             
       {/* top  */}
       <main className="flex flex-row items-center footer_main_wrapper justify-between">

             <div className="flex footer_left_midd_wrapper flex-row items-center justify-between w-[55%] ">

            
        {/* left  */}
        <div className="flex flex-col gap-[40px]">
            <p style={{
                fontFamily:"sebino-regular"
            }} className="w-[300px]"><img src={logo} alt="" /> </p>
            {
                footerData?.map((item , index)=>(
                    <div key={index} className="flex  flex-row gap-[24px]">
                        <img className="max-w-[28px] footer_left_img" src={item.image} alt="" />
                        <p  style={{
                fontFamily:"Roboto-regular"
            }} className="font-[400] footer_left_title text-[20px] w-[80%] text-white ">{item.title}</p>
                    </div>
                ))
            }

        </div>

        {/* middle part */}
        <div  style={{
                fontFamily:"sebino-regular"
            }} className="flex flex-col  h-[400px] gap-[25px] pt-20">
            <p style={{
                fontFamily:"Poppins-bold"
            }} className="font-[600] text-[24px] text-white footer_menu_text">Menu</p>
           {footerItem?.map((item ,index)=>(
       
              <Link to={item.move}>  <p style={{
                    fontFamily:"Poppins-regular"
                }} className="text-white font-[500] text-[20px] footer_menu_items cursor-pointer " key={index}>{item.title}</p> </Link>
                
           ))}
        </div>
        </div>

        {/* end part */}
        <div className="flex flex-col gap-[40px] footer_main_end justify-end ">
            {/* top  */}
            <div className="flex flex-row  ">
                  <div onClick={()=>navigate("/YesPitchMe")}  style={{
                fontFamily:"Poppins-bold"
            }} className="w-[240px] text-center cursor-pointer footer_end_left h-[130px] flex items-center bg-[#42BDAC33] text-white justify-center  rounded-tl-[8px] rounded-bl-[8px] font-[500] text-[32px] ">
                  Let’s Talk Sales
                  </div>
                  <div onClick={()=>navigate("/YesPitchMe")} className="w-[100px] cursor-pointer footer_end_right h-[130px] flex justify-center items-center bg-[#42BDAC]  rounded-tr-[8px] rounded-br-[8px]">
               <img src={arrow} alt="" />
                  </div> 
            </div>
            {/* bottom */}
            <div className="w-[90%] pl-2 linkedInImg  flex flex-row justify-end">
            <a href={linkedInProfileUrl} target="_blank" rel="noopener noreferrer">
            <img src={inImg} alt="LinkedIn" className="max-w-[24px] text-right" />
            </a>
            </div>
        </div>
        
       </main>
       


       
       {/* bottom  */}
       <div className="w-full flex justify-center pt-[113px]">
        <p style={{
            fontFamily:"sebino-regular"
        }} className="font-[400] text-[12px] text-white translate-y-[40px] ">Copyright © Grow10x, Inc. 2023</p>
       </div>
     
        </div>
    )
}

export default FooterSection;