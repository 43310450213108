import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { navItems } from "../../data/navItems";
import { TfiMenu } from "react-icons/tfi";
import { useEffect } from "react";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { setShowSMenu } from "../../reducer/slices/showMenuSlice";
import "./common.css";

function Navbar() {
  const dispatch = useDispatch();
  const { showMenu } = useSelector((state) => state.showMenu);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 950) {
        dispatch(setShowSMenu(false));
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setShowSMenu(false));
  }, []);

  return (
    <nav className="flex flex-row justify-between bg-[#0E1725]   z-[10000] pb-5 fixed top-0 w-[100%] items-center text-white pl-[36px] pt-5 pr-[20px] lg:pr-[90px]  ">
      <Link to={"/"}>
        {" "}
        <img
          src={logo}
          alt="GROW10X"
          className="cursor-pointer w-[213px] nav_imgLogo "
        />
      </Link>

      {/* navitems */}
      <div className=" navbar_wrapper flex flex-row justify-between w-[70%] mb-1 lg:w-[65%] ">
        {navItems.map((item, index) => {
          return (
            <div key={index}>
              
                <Link to={item.move}>
                  <div
                    style={{
                      fontFamily: "Poppins-semibold",
                    }}
                    className={`text-[16px] flex flex-row items-center gap-1 relative cursor-pointer hover:text-[#42BDAC] font-[600] `}
                  >
                    {item.title}
                  </div>
                </Link>
              
            </div>
          );
        })}
      </div>



      <TfiMenu
        onClick={() => dispatch(setShowSMenu(true))}
        className="hidden nav_menuIcon text-3xl mb-2 "
      />

      <div
        className={`fixed bottom-0 left-0 w-[calc(100vw-300px)] ${
          showMenu ? "menu_filter_left" : "hidden"
        } h-[100vh]`}
      ></div>

      <div
        className={` opacity-1 ${showMenu && "nav_show_menu"} ${
          !showMenu && "nav_hide_menu"
        } nav_menu_bar  bg-[#0E1725] fixed w-[300px] flex flex-col items-center  gap-[40px] pt-6 h-[100vh] right-0 top-0  `}
      >
        <div className="w-full flex justify-end pr-6">
          <ImCross
            onClick={() => dispatch(setShowSMenu(false))}
            className="text-white text-[30px] cursor-pointer  "
          />
        </div>
        {navItems.map((item, index) =>
       
            <Link key={index} to={`${item.move}`}>
              <p
                style={{
                  fontFamily: "Poppins-semibold",
                }}
                className="text-[18px] text-white  cursor-pointer hover:text-[#42BDAC] font-[500] "
              >
                {item.title}
              </p>
            </Link>
          
        )}
      </div>
    </nav>
  );
}

export default Navbar;
