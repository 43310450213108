import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import rootReducer from './reducer';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import ScrollToTop from './ScrollToTop';

const store = configureStore({
    reducer:rootReducer,
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store} >
<BrowserRouter>
<ScrollToTop />
<App />
</BrowserRouter>
</Provider>
 
);

