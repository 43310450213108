import { useState , useEffect, useReducer, useRef } from "react";
import "./musicSection.css"
import {ImPlay3} from "react-icons/im"
import {AiOutlinePause} from "react-icons/ai"
import audio1 from "../../audio/1.mp3";
import audio2 from "../../audio/3.mp3";
import { Howl, Howler } from "howler";
import FadeIn from 'react-fade-in';


function MusicSection(){
 

        const [soundPlayed1 , setSoundPlayed1] = useState(null);
        const [soundPlayed2 , setSoundPlayed2] = useState(null);
        const [isPaused1 , setIsPaused1] = useState(true);
        const [isPaused2 , setIsPaused2] = useState(true);


 const pauseSound1 = ()=>{
  soundPlayed1.stop();
 }

 const togglePlayPause1 = ()=>{
  if(isPaused1){
    playSound1(audio1);
    setIsPaused1(false);
  }else{
    pauseSound1();
    setIsPaused1(true);
  }
 }
      




 const pauseSound2 = ()=>{
  soundPlayed2.stop();
 }
      
 const togglePlayPause2 = ()=>{
  if(isPaused2){
    playSound2(audio2);
    setIsPaused2(false);
  }else{
    pauseSound2();
    setIsPaused2(true);
  }
 }


 
      // for second 
      const playSound2 = (songSrc) => {
 
      
        if(soundPlayed2){
          soundPlayed2.stop();
        }
    
        var sound = new Howl({
          src: [songSrc],
          html5: true,
        });
       
        setSoundPlayed2(sound);
    
        sound.play();
      
      };
 
 const playSound1 = (songSrc) => {
 
      if(soundPlayed1){
        soundPlayed1.stop();
        
      }
  
      var sound = new Howl({
        src: [songSrc],
        html5: true,
      });
     
      setSoundPlayed1(sound);
  
      sound.play();
    };

      
   
    return (
        <section className="w-full flex flex-col items-center gap-[20px] pt-[100px] justify-center    ">
  
           <p style={{
            fontFamily:"Poppins-bold"
          }} className="text-[36px] text-white text-center musicSectioneheading">Just listen to our cold calls</p>
                      
                      {/* for first song */}
                      <div  className="w-full flex flex-row items-center h-[200px] gap-[90px] mt-[50px] justify-center">

                      
            {/* left part  */}
            <div className="musicWrapper h-[300px]">
            <div className={` ${!isPaused1?('loader'):('h-[70px] flex gap-2')} `}>

            <span className={` ${!isPaused1?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[70%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[70%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[40%] w-[5px] rounded-[50px] mx-0 my-5  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused1?('stroke'):('h-[90%] w-[5px] rounded-[50px] mx-0 my-1  bg-white ')}  `}></span>

              <span className={` ${!isPaused1?('stroke'):('h-[50%] w-[5px] rounded-[50px] mx-0  my-3 bg-white ')}  `}></span>

              <span className={` ${!isPaused1?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-2  bg-white ')}  `}></span>

              <span className={` ${!isPaused1?('stroke'):('h-[100%] w-[5px] rounded-[50px] mx-0   bg-white ')}  `}></span>

              <span className={` ${!isPaused1?('stroke'):('h-[40%] w-[5px] rounded-[50px] mx-0 my-4  bg-white ')}  `}></span>

              <span className={` ${!isPaused1?('stroke'):('h-[30%] w-[5px] rounded-[50px] mx-0 my-5  bg-white ')}  `}></span>
            </div>
            </div>

            {/* right part  */}
            <div>
                 <div  className={`w-[100px] music_right_circle  relative music_single_circle  h-[100px] rounded-full border-2   border-[#42BDAC] flex items-center justify-center  `}>
                    
                        {
                            isPaused1 ?(
                                <ImPlay3 onClick={togglePlayPause1} className="text-white z-20 text-[40px] cursor-pointer  " />

                            ):(
                                <AiOutlinePause onClick={togglePlayPause1} className="text-white z-20 text-[40px] cursor-pointer  " />

                            )
                        } 
                 </div>
             
     
            </div>
            </div>

            {/* for second song wrapper */}
                      <div  className="w-full flex flex-row items-center  h-[200px] gap-[90px] pt-14 justify-center  ">

                      
            {/* left part  */}
            <div className="musicWrapper h-[300px]">
            <div className={` ${!isPaused2?('loader'):('h-[70px] flex gap-2')} `}>

              <span className={` ${!isPaused2?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[70%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[70%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[20%] w-[5px] rounded-[50px] mx-0 my-6  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[40%] w-[5px] rounded-[50px] mx-0 my-5  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-3  bg-white ')}  `}></span>
              <span className={` ${!isPaused2?('stroke'):('h-[90%] w-[5px] rounded-[50px] mx-0 my-1  bg-white ')}  `}></span>

              <span className={` ${!isPaused2?('stroke'):('h-[50%] w-[5px] rounded-[50px] mx-0  my-3 bg-white ')}  `}></span>

              <span className={` ${!isPaused2?('stroke'):('h-[60%] w-[5px] rounded-[50px] mx-0 my-2  bg-white ')}  `}></span>

              <span className={` ${!isPaused2?('stroke'):('h-[100%] w-[5px] rounded-[50px] mx-0   bg-white ')}  `}></span>

              <span className={` ${!isPaused2?('stroke'):('h-[40%] w-[5px] rounded-[50px] mx-0 my-4  bg-white ')}  `}></span>

              <span className={` ${!isPaused2?('stroke'):('h-[30%] w-[5px] rounded-[50px] mx-0 my-5  bg-white ')}  `}></span>
            </div>
            </div>

            {/* right part  */}
            <div>
                 <div  className={`w-[100px]  relative music_single_circle  music_right_circle h-[100px]  rounded-full border-2   border-[#42BDAC] flex items-center justify-center `}>
                    
                        {
                            isPaused2 ?(
                                <ImPlay3 onClick={togglePlayPause2} className="text-white z-20 text-[40px] cursor-pointer  " />

                            ):(
                                <AiOutlinePause onClick={togglePlayPause2} className="text-white z-20 text-[40px] cursor-pointer  " />

                            )
                        } 
                 </div>
             
     
            </div>
            </div>


           

            </section>
    )
}

export default MusicSection;