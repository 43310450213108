import { useState } from "react";
import {
  IndustryProperties1,
  IndustryProperties2, data
} from "../../data/homePageData";



function InsdustryDataSection() {
  
  const [currentText , setCurrentText] = useState(0);
    

  return (
    <section style={{fontFamily:"Poppins"}} className="bg-[#0E1725]  mt-[-2px] mb-[-2px] w-full pb-[40px]  ">
      <div className="w-full industry_container  flex  flex-row justify-between">

        {/* left part -> line animation */}
        <div className="w-[50%] industry_left ml-[40px] flex flex-row gap-7 items-center">
           {/* line */}
           <div className="flex flex-col ">
                 {
                    data.map((_ ,index)=>(
              <div key={index} className={`${currentText === index?('h-[170px] translate-y-[-15px] bg-gray-300 bg-opacity-20 '):('bg-gray-300 opacity-20 h-[100px] translate-y-[-15px] ')} w-[4px]  `}>
            
                      <p  className={`${currentText === index ? ('bg-[#42BDAC] showLineAnimation   '):(' h-full ')} w-[4px]  $ `}></p>   
                      </div>
                       
                    ))
                 }
           </div>

           {/* data of text */}
           <div className="flex flex-col gap-[50px] w-[90%]">
            {
                data.map((item , index)=>(
                    <div className={`flex flex-col gap-3  `} key={index}>
                      <p style={{
                        fontFamily:"Avram-semibold"
                      }} onClick={()=>
                        {      setCurrentText(index)}
                         } className={` ${currentText=== index ?(''):('text-opacity-70 hover:text-[#5FC8B9] cursor-pointer')} font-[500] text-[25px] text-white indus_left_title `}>{item.title}</p>
                         
                         <div className={` ${currentText === index ?('h-[150px] '):('')}  font-[200] text-[19px] text-white opacity-70 `}>

                         <p  style={{ fontFamily:"sebino-regular"  }} 
                      className={` ${currentText === index ?(' showParaAnimation '):('hidden')}  font-[300] text-[19px] text-[#D2D2D2[] indus_left_para `}>{item.para}</p>

                         </div>
                   
                    </div>
                ))
            }
           </div>
        </div>

        {/* right part */}
        <div  style={{
                fontFamily:"sebino-regular"
            }} className="w-[590px] industry_right rounded-[7px]  h-[467px] flex flex-col  gap-[30px] bg-[#5FC8B9] pl-[50px] pt-[35px] pr-[40px]  mr-[27px] ">
          <p style={{
            fontFamily:"Poppins-bold"
          }} className="font-[700] text-[40px] text-black ">Industries</p>
          <p style={{
            fontFamily:"Avram-bold"
          }} className="font-[700] text-[20px] text-black w-full ">
            Grow10X has extensive experience in generating leads in these
            industries
          </p>

          {/* table type */}
          <div className="flex flex-row justify-between indus_right_table w-[100%] mx-auto max-h-[400px] font-[400] text-[20px]  ">
            {/* left */}
            <div className="flex flex-col gap-4">
              {IndustryProperties1.map((data, index) => (
                <p style={{
                  fontFamily:"sebino-regular"
                }} key={index}>{data}</p>
              ))}
            </div>

            {/* right  */}
            <div className="flex flex-col gap-4">
              {IndustryProperties2.map((data, index) => (
                <p style={{
                  fontFamily:"sebino-regular"
                }} key={index}>{data}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InsdustryDataSection;
