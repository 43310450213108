import FooterSection from "./Common/FooterSection";
import Navbar from "./Common/Navbar";
import { secretData } from "../data/secretData";
import { setShowSMenu } from "../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "./page.css";
import FadeIn from "react-fade-in/lib/FadeIn";


function SecretPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowSMenu(false));
  }, []);

  return (
    <div className="bg-[#0E1725] flex secretPage_container flex-col gap-[150px]   ">
      <Navbar />

<FadeIn>


      <section className="w-[877px] pt-[240px]  secretSec1 h-fit flex flex-col gap-[22px] ml-[80px] ">
        <p style={{
          fontFamily:"Poppins-bold"
        }} className="font-[700] text-[60px] text-white secretSec1_heading  ">
          Secret Sauce
        </p>
        <p
          style={{
            fontFamily: "sebino-regular",
          }}
          className="font-[500] text-white secretSec1_para text-[25px] opacity-90 "
        >
          Grow10X provides an experienced sales infrastructure focused on
          sourcing, training, managing, and retaining a talented workforce of
          Sales Development Reps for growth focused companies.
        </p>
      </section>
      

      <section className="w-full flex flex-col  gap-[200px] mt-[200px] secret_sec2 ">
        {secretData.map((data, index) => (
          <div
            key={index}
            className="flex flex-row gap-[64px] w-full justify-center single_secret_container items-center "
          >
            {/* left  */}
            <div className="w-[600px] single_secret_left  flex flex-col gap-[20px]  ">
              <div className="flex flex-row gap-[40px] single_secret_num_title_container">
                <div className="bg-[#42BDAC]  flex flex-row  items-center justify-center w-[40px] h-[40px] rounded-[10px] ">
                  <p className="text-white font-[400]  ">{data.num}</p>
                </div>
                <p
                  style={{ fontFamily: "Avram-semibold" }}
                  className="text-white  font-[600] text-[28px]  w-[80%] single_secret_title "
                >
                  {data.title}
                </p>
              </div>

              <p
                style={{
                  fontFamily: "sebino-regular",
                }}
                className="text-[#D2D2D2] text-[24px] single_secret_para font-[400] text-center "
              >
                {data.para}
              </p>
            </div>

            {/* right */}
            <div className="flex single_secret_right items-center justify-center rounded-[10px]  w-[452px] h-[362px] bg-white bg-opacity-[9%] ">
              <img src={data.img} alt="" className="w-[161px]   h-[159px] " />
            </div>
          </div>
        ))}
      </section>
      </FadeIn>

      <FooterSection />
    </div>
  );
}

export default SecretPage;
